import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://427d768aa3954d207ea97c7ea7ccb83b@o4504999543767040.ingest.sentry.io/4506534587334656",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()